import { useCallback, useEffect, useState } from 'react';
import { request } from './API';
import './App.css';
import { FindingsPopup } from './components/FindingsPopup';
import { MatcherCard } from './components/MatcherCard';
import { Spinner } from './components/Spinner';

export type PasteMatcher = {
	id:          string,
	name:        string,
	description: string,
	regex:       string,
	flags:       string,
	cnt:         number,
	latest:      Date
}

let reloadInterval: NodeJS.Timer;
const INTERVAL_TIMER = 60000;

function App() {
    const [loading, setLoading] = useState(true);
	const [matchers, setMatchers] = useState<PasteMatcher[]>([]);
	const [selectedMatcher, setSelectedMatcher] = useState<PasteMatcher | null>(null);

	const getMatchers = useCallback(async () => {
		try {
			const resMatchers = (await request("matchers")) as PasteMatcher[];
			setMatchers(resMatchers);
		}
		finally {
			setLoading(false);
			if(!reloadInterval) {
				reloadInterval = setInterval(() => getMatchers(), INTERVAL_TIMER);
			}
		}
	}, []);

	useEffect(() => {
		getMatchers();
	}, [getMatchers]);

	return (
		<>
		<div className='app-title'>
			<img className='app-title-logo' src="/logo192.png" alt="Pastescraper Logo"/>
			<h1>Pastescraper</h1>
		</div>

		<div className='app-text-container'>
			<div className='app-text'>
				<h2>What's this?</h2>
				Pastescraper is a service which automatically scrapes public posts on Pastebin and matches it with a given set of Regular Expressions, storing the results of these matches.
				This web interface lists all results somewhat live.

				<h2>Is that allowed?</h2>
				The program powering Pastescraper is using Pastebin's authorized scraping API through a legitimate, paid Pastebin PRO account.
				The use of data obtained through this interface is not limited in any way by Pastebin's Terms of Service (as defined in Section C.6 of Pastebin's Terms of Service).
				All data shown on this website is currently, or was at one point, publicly available, making this website an archiving service in nature.
				The data on this website is intended to be used for research and educational purposes and only displays small segments rather than the full contents of posts, making it eligible for Fair Use in terms of copyright.
				<br/><br/>
				tl;dr: Yes.

				<h2>Can I add my own Regular Expressions?</h2>
				No. The site is provided as-is, with no warranties, guarantees or any way of modifying the site contents.

				<h2>Can I access a full list of results for matchers?</h2>
				If you know who runs this site and ask them nicely: maybe. In most cases: no.
			</div>
		</div>

		<div className='app-findings-container'>
			{loading && <Spinner/>}
			{!loading && (
				matchers.map(matcher => <MatcherCard key={matcher.id} matcher={matcher} onClick={() => setSelectedMatcher(matcher)}/>)
			)}
		</div>

		{selectedMatcher && (
			<FindingsPopup matcher={selectedMatcher} onClose={() => setSelectedMatcher(null)}/>
		)}
		</>
	);
}

export default App;

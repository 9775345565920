import ReactTimeAgo from "react-time-ago";
import { PasteMatcher } from "../../App";
import "./MatcherCard.css";

export function MatcherCard(props: {matcher: PasteMatcher, onClick: Function}) {
    return (
        <div className="matcher-card" onClick={() => props.onClick()}>
            <h3>{props.matcher.name}</h3>
            
            <b>Total Findings:</b> <code>{props.matcher.cnt}</code><br/>
            <b>Last Finding:</b>   <code>{props.matcher.latest !== null ? <ReactTimeAgo date={new Date(props.matcher.latest)}/> : "Never"}</code><br/><br/>
            {props.matcher.description}
        </div>
    )
}
import { useCallback, useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { request } from "../../API";
import { PasteMatcher } from "../../App";
import { Finding } from "../Finding";
import { Spinner } from "../Spinner";
import "./FindingsPopup.css";

export type PasteFinding = {
    id: number,
    timestamp: Date,
    matcher: string,
    match: string[],
    paste: string
}

function parseFinding(finding: any) {
    return {
        ...finding,
        match: JSON.parse(finding.match)
    }
}

export function FindingsPopup(props: {matcher: PasteMatcher, onClose: Function}) {
    const [loading, setLoading] = useState(true);
	const [findings, setFindings] = useState<PasteFinding[]>([]);

	const getFindings = useCallback(async () => {
		try {
			const resFindings = (await request(`findings?matcher=${props.matcher.id}`)).map(parseFinding) as PasteFinding[];
			setFindings(resFindings);
		}
		finally {
			setLoading(false);
		}
	}, [props.matcher.id]);

	useEffect(() => {
		getFindings();
	}, [getFindings]);

    return (
        <div className="findings-popup" onClick={(e) => { if(e.target === e.currentTarget) props.onClose() }}>
            <div className="findings-popup-container">
                <h2>{props.matcher.name}</h2>
                <p><b>Total Findings:</b> <code>{props.matcher.cnt}</code></p>
                <p><b>Latest Finding:</b> {props.matcher.latest ? <code><ReactTimeAgo date={new Date(props.matcher.latest)}/></code> : <code>Never</code>}</p>
                <p>
                    <SyntaxHighlighter language="js" style={darcula}>
                        {`/${props.matcher.regex}/${props.matcher.flags}`}
                    </SyntaxHighlighter>
                </p>
                <p>{props.matcher.description}</p>
                <h3>Latest 10 Findings:</h3>
                <div className="findings-popup-list">
                    {loading && <Spinner/>}
                    {!loading && findings.map(finding => <Finding key={finding.id} finding={finding}/>)}
                    {(!loading && findings.length === 0) && "Nothing to show here."}
                </div>
                <br/>
            </div>
        </div>
    )
}
import "./Finding.css";
import { PasteFinding } from "../FindingsPopup";
import ReactTimeAgo from "react-time-ago";

export function Finding(props: {finding: PasteFinding}) {
    return (
        <div className="finding">
            <a href={`https://pastebin.com/${props.finding.paste}`}>{props.finding.paste}</a>
            <div>
                "{props.finding.match[0]}"
            </div>
            <ReactTimeAgo date={new Date(props.finding.timestamp)}/>
        </div>
    )
}